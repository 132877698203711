class Header {
	constructor() {
		this.open = false;
		this.hidden = false;
		this.lastScroll = 0;
		this.top = true;
		this.needsRaf = false;

		this.header = document.getElementById('header');
		this.burger = document.getElementById('burger');
		this.langBtn = this.header.querySelector('.langBtn');
		this.searchBtn = this.header.querySelector('.searchBtn');
		this.searchInput = this.header.querySelector('.search input');

		this.burger.onclick = () => this.toggleMenu();
		this.langBtn.onclick = () => this.toggleMenu();
		this.searchBtn.onclick = () => this.toggleMenu(true);

		window.addEventListener('scroll', () => this.onScroll());
		window.addEventListener('mousemove', e => this.onMouseMove(e.clientY));
	}

	toggleMenu(focusSearch = false) {
		if (this.open) {
			this.header.classList.remove('open');
		} else {
			this.header.classList.add('open');
			if (focusSearch) this.searchInput.focus();
		}
		this.open = !this.open;
	}

	onScroll() {
		this.setTop(window.pageYOffset < 200);
		this.setHidden(window.pageYOffset > 0 && window.pageYOffset >= this.lastScroll);
		this.lastScroll = window.pageYOffset;
	}

	onMouseMove(y) {
		if (y < 150 && this.hidden) {
			this.hidden = false;
			this.header.classList.remove('hidden');
		}
	}

	setTop(top) {
		if (this.top === top) return;
		this.top = top;
		if (top) this.header.classList.add('top');
		else this.header.classList.remove('top');
	}

	setHidden(hidden) {
		if (this.hidden === hidden) return;
		this.hidden = hidden;
		if (this.hidden) this.header.classList.add('hidden');
		else this.header.classList.remove('hidden');
	}
}

new Header();
