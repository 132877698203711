class SliderHome {
	constructor(id) {
		this.dragging = false;
		this.pointerPos = { x: 0 };
		this.lastPointerPos = { x: 0 };
		this.sliderPos = { x: 0 };
		this.lastSliderPos = { x: 0 }
		this.totalDist = 0;
		this.currentSlide = 0;
		this.momentumTween = null;
		this.distLog = [];
		this.velocity = 10;
		this.duration = .8;
		this.slideWidth = 0;
		this.scrolledAmount = 0;
		this.backgroundInView = true;

		this.slider = document.getElementById(id);
		this.intervalDuration = +this.slider.getAttribute('data-interval') * 1000;
		this.gradient = this.slider.querySelector('.gradient');
		this.buttons = this.slider.querySelectorAll('button');
		this.area = this.slider.querySelector('h1');
		this.slidesWrapper = this.slider.querySelector('.slidesWrapper');
		this.slides = this.slidesWrapper.querySelectorAll('.slide');
		this.background = this.slider.querySelector('.background');

		this.initWebGL();
		this.bind();
		this.onResize();
		this.onScroll();
		this.resizeSlider();
		this.interval = setInterval(() => {
			if (this.scrolledAmount > 0) return;
			const index = (this.currentSlide + 1) % this.slides.length;
			this.setSliderPos(index);
		}, this.intervalDuration);
	}

	bind() {
		this.buttons.forEach((btn, i) => {
			btn.onclick = () => {
				clearInterval(this.interval);
				this.setSliderPos(i);
			};
		});

		this.area.addEventListener('mousedown', e => this.onPointerDown(e.pageX));
		this.area.addEventListener('touchstart', e => this.onPointerDown(e.touches[0].pageX));

		window.addEventListener('mousemove', e => this.onPointerMove(e.pageX));
		window.addEventListener('touchmove', e => this.onPointerMove(e.touches[0].pageX));
		window.addEventListener('mouseup', () => this.onPointerUp());
		window.addEventListener('touchend', () => this.onPointerUp());
		window.addEventListener('keydown', e => this.onKeyDown(e.key));
		window.addEventListener('resize', () => this.onResize());
		window.addEventListener('scroll', () => this.onScroll());

		this.observer = new IntersectionObserver(([entry]) => this.backgroundInView = entry.isIntersecting, { threshold: 1.0 })
		this.observer.observe(this.background);
	}

	onResize() {
		this.calcSlideWidth();
		this.setSliderPos(this.currentSlide, false);
		const wW = window.innerWidth;
		this.wH = window.innerHeight;
		this.velocity = wW >= 1024 ? 10 : wW >= 768 ? 12 : 20

		// pas de resize sur mobile parce qu'on veut pas que la height change brutalement à cause des bandeaux du navigateur
		if (wW > 768) this.resizeSlider();
	}

	resizeSlider() {
		this.slider.style.height = window.innerHeight + 'px';
	}

	calcSlideWidth() {
		this.slideWidth = this.slides[0].clientWidth;
	}

	onPointerDown(x) {
		clearInterval(this.interval);
		this.dragging = true;
		this.pointerPos = { x };
		this.lastPointerPos = { x };
		this.totalDist = 0;
		this.distLog = [];
		this.stopMomentum();
		this.updateSliderPosLoop();
	}

	onPointerMove(x) {
		if (this.dragging) this.pointerPos = { x };
	}

	onPointerUp() {
		if (!this.dragging) return;

		this.dragging = false;

		const releaseSpeed = this.distLog.reduce((a, b) => a + b, 0) / (this.distLog.length || 1);

		let targetX = this.sliderPos.x + releaseSpeed * this.velocity;
		targetX = Math.round(targetX / this.slideWidth) * this.slideWidth;
		let targetSlide = -targetX / this.slideWidth;
		let excess = 0;

		if (targetSlide < 0) {
			excess = targetSlide;
			targetSlide = 0;
		} else if (targetSlide >= this.slides.length) {
			excess = targetSlide - (this.slides.length - 1);
			targetSlide = this.slides.length - 1;
		}
		if (excess !== 0) targetX = -targetSlide * this.slideWidth;

		this.momentumTween = gsap.to(this.sliderPos, {
			duration: this.duration - Math.abs(excess) / 20,
			x: targetX,
			ease: 'power2',
			onUpdate: () => this.updateSliderPos(),
			onComplete: () => this.updateSliderPos(),
		});
	}

	updateSliderPos() {
		gsap.set(this.slidesWrapper, {
			x: this.sliderPos.x + (window.innerWidth - this.slideWidth) / 2,
			lazy: true,
		});
		this.lastSliderPos.x = this.sliderPos.x;
		this.setIndex(Math.round(-this.sliderPos.x / this.slideWidth));
	};

	stopMomentum() {
		if (!this.momentumTween) return;
		this.momentumTween.kill();
		this.momentumTween = null;
	}

	setSliderPos(index, isAnimated = true) {
		this.stopMomentum();

		this.momentumTween = gsap.to(this.sliderPos, {
			duration: isAnimated ? this.duration : 0,
			x: -index * this.slideWidth,
			ease: 'power4',
			onUpdate: () => this.updateSliderPos(),
			onComplete: () => this.updateSliderPos(),
		});
	}

	updateSliderPosLoop() {
		if (!this.dragging || !this.backgroundInView) return;
		this.updateSliderPos();
		const dist = this.pointerPos.x - this.lastPointerPos.x;
		this.lastPointerPos.x = this.pointerPos.x;
		this.totalDist += dist;
		this.sliderPos.x += dist;
		this.distLog.push(dist);
		while (this.distLog.length > 10) this.distLog.splice(0, 1);
		requestAnimationFrame(() => this.updateSliderPosLoop());
	};

	onKeyDown(key) {
		clearInterval(this.interval);
		if (key === 'ArrowRight') this.next();
		else if (key === 'ArrowLeft') this.prev();
	};

	prev() {
		if (this.currentSlide > 0 && this.scrolledAmount === 0) {
			this.setSliderPos(this.currentSlide - 1);
		}
	};

	next() {
		if (this.currentSlide < this.slides.length - 1 && this.scrolledAmount === 0) {
			this.setSliderPos(this.currentSlide + 1);
		}
	};

	setIndex(index) {
		if (index === this.currentSlide || index < 0 || index >= this.slides.length) return;

		this.sketch.goTo(this.currentSlide, index);
		this.currentSlide = index;

		this.buttons.forEach((btn, i) => {
			if (index === i) {
				btn.classList.add('active');
				this.slides[i].classList.add('active');
			} else {
				btn.classList.remove('active');
				this.slides[i].classList.remove('active');
			}
		});
	}

	onScroll() {
		this.scrolledAmount = window.pageYOffset;
		if (!this.ticking) {
			this.ticking = true;
			requestAnimationFrame(() => this.updateBackgroundPos());
		}
	}

	updateBackgroundPos() {
		const transform = `translate3d(0, ${this.scrolledAmount / 2}px, 0)`;
		this.background.style.transform = transform;
		this.gradient.style.transform = transform;
		this.background.style.opacity = this.scrolledAmount / (this.wH * -1.5) + 1;
		this.ticking = false;
	}

	initWebGL() {
		this.sketch = new Sketch({
			duration: 0.6,
			debug: false,
			easing: 'ease',
			container: document.querySelector('#sliderHome .background'),
			uniforms: {},
			fragment: `
			uniform float time;
			uniform float progress;
			uniform sampler2D texture1;
			uniform sampler2D texture2;
			uniform vec4 resolution;
			varying vec2 vUv;
			varying vec4 vPosition;
			void main()	{
				vec2 newUV = (vUv - vec2(0.5))*resolution.zw + vec2(0.5);
				vec2 p = newUV;
				float x = progress;
				x = smoothstep(.0,1.0,(x*2.0+p.x-1.0));
				vec4 f = mix(
					texture2D(texture1, (p-.5)*(1.+x)+.5), 
					texture2D(texture2, (p-.5)*x+.5), 
					x);
					gl_FragColor = f;
				}
				`
		});
	}
}

if (document.getElementById('sliderHome')) new SliderHome('sliderHome');
