class FakePopup {
	constructor(btnsSelector, cacheId) {
		this.btns = document.querySelectorAll(btnsSelector);
		this.cache = document.getElementById(cacheId);
		this.active = false;
		this.bind();
	}

	bind() {
		this.cache.onclick = () => this.closeAll();

		this.btns.forEach((btn, i) => {
			btn.onclick = () => {
				if (this.active) this.closeAll()
				else this.open(i);
			}
		})
	}

	open(index) {
		this.active = true;
		this.cache.classList.add('visible');
		this.btns[index].classList.add('active');
	}

	closeAll() {
		this.active = false;
		this.cache.classList.remove('visible');
		this.btns.forEach(btn => btn.classList.remove('active'))
	}
}

if (document.getElementById('popupCache')) {
	new FakePopup('.cta.fakePopup', 'popupCache');
}
