class Menu {
	constructor(id) {
		this.menu = document.getElementById(id);
		this.bigLinksWrapper = this.menu.querySelector('.bigLinks');
		this.bigLinks = this.menu.querySelectorAll('.bigLink');
		this.subMenus = this.menu.querySelectorAll('.subMenu');
		this.backButtons = this.menu.querySelectorAll('.backToBigLinks');

		this.bind();
	}

	bind() {
		this.bigLinks.forEach((btn, i) => {
			const id = btn.getAttribute('data-id');

			if (id) {
				btn.onmouseover = () => {
					this.bigLinks.forEach((bigLink, o) => {
						this.bigLinksWrapper.classList.add('hidden');
						if (i === o) bigLink.classList.add('active');
						else bigLink.classList.remove('active');
					});

					this.subMenus.forEach(subMenu => {
						if (subMenu.id === 'subMenu-' + id) subMenu.classList.remove('hidden');
						else subMenu.classList.add('hidden');
					});
				};
			} else {
				btn.onmouseover = () => this.closeAll();
			}
		});

		this.backButtons.forEach(btn => {
			btn.onclick = () => this.closeAll();
		});
	}

	closeAll() {
		this.bigLinks.forEach(btn => btn.classList.remove('active'))
		this.subMenus.forEach(subMenu => subMenu.classList.add('hidden'))
		this.bigLinksWrapper.classList.remove('hidden');
	}
}

if (document.getElementById('mainMenu')) new Menu('mainMenu');
