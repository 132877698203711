class CarouselPresentationHome {
	constructor(selector) {
		this.wrapper = document.querySelector(selector);
		this.imgWrapper = this.wrapper.querySelector('.images');
		this.figures = this.wrapper.querySelectorAll('figure');
		this.buttons = this.wrapper.querySelectorAll('button');
		this.texts = this.wrapper.querySelectorAll('.text p');
		this.index = 0;
		this.pointerX = 0;
		this.pointerDown = false;
		this.inView = true;

		this.bind();
		this.interval = setInterval(() => {
			if (!this.inView) return;
			this.goTo((this.index + 1) % this.buttons.length);
		}, 3000);
	}

	bind() {
		this.buttons.forEach((btn, i) => {
			btn.onclick = () => {
				clearInterval(this.interval);
				this.goTo(i);
			};
		});

		this.imgWrapper.addEventListener('touchstart', e => this.onPointerDown(e.touches[0].pageX));
		this.imgWrapper.addEventListener('mousedown', e => this.onPointerDown(e.pageX));
		window.addEventListener('touchmove', e => this.onPointerMove(e.touches[0].pageX));
		window.addEventListener('mousemove', e => this.onPointerMove(e.pageX));
		window.addEventListener('touchend', () => this.onPointerUp());
		window.addEventListener('mouseup', () => this.onPointerUp());

		this.observer = new IntersectionObserver(([entry]) => {
			this.inView = entry.isIntersecting;
		}, { threshold: 1 });
		this.observer.observe(this.imgWrapper);
	}

	goTo(index) {
		this.index = index;
		this.buttons.forEach((btn, i) => {
			if (i === index) {
				btn.classList.add('active');
				this.texts[i].classList.add('active');
				this.figures[i].className = '';
			} else {
				btn.classList.remove('active');
				this.texts[i].classList.remove('active');
				if (i > index) this.figures[i].className = 'right';
				else this.figures[i].className = 'left';
			}
		})
	}

	onPointerDown(x) {
		if (!this.inView) return;
		this.pointerX = x;
		this.pointerDown = true;
		clearInterval(this.interval);
	}

	onPointerMove(x) {
		if (!this.pointerDown) return;
		if (x < this.pointerX - 100 && this.index < this.buttons.length - 1) {
			this.goTo(this.index + 1);
			this.pointerDown = false;
		} else if (x > this.pointerX + 100 && this.index !== 0) {
			this.goTo(this.index - 1);
			this.pointerDown = false;
		}
	}

	onPointerUp() {
		this.pointerDown = false
	}
}

if (document.querySelector('#presentation .carousel')) new CarouselPresentationHome('#presentation .carousel');
