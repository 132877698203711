class SliderActualites {
	constructor(id) {
		this.dragging = false;
		this.pointerPos = { x: 0 };
		this.lastPointerPos = { x: 0 };
		this.sliderPos = { x: 0 };
		this.lastSliderPos = { x: 0 }
		this.totalDist = 0;
		this.currentSlide = 0;
		this.momentumTween = null;
		this.distLog = [];
		this.velocity = 10;
		this.duration = .8;
		this.slideWidth = 0;
		this.inView = true;
		this.nbrPerSlide = 3;

		this.slider = document.getElementById(id);
		this.btnLeft = this.slider.querySelector('.nav.left');
		this.btnRight = this.slider.querySelector('.nav.right');
		this.sliderArea = this.slider.querySelector('.sliderArea');
		this.slidesWrapper = this.slider.querySelector('.slidesWrapper');
		this.slides = this.slidesWrapper.querySelectorAll('.slide');
		this.links = this.slidesWrapper.querySelectorAll('a');

		this.bind();
		this.onResize();
	}

	bind() {
		this.btnLeft.onclick = () => this.prev();
		this.btnRight.onclick = () => this.next();

		this.sliderArea.addEventListener('mousedown', e => this.onPointerDown(e.pageX));
		this.sliderArea.addEventListener('touchstart', e => {
			this.slidingVertically = false;
			this.y = e.touches[0].pageY;
			this.onPointerDown(e.touches[0].pageX);
		});

		this.links.forEach(link => {
			link.addEventListener('click', e => {
				if (this.totalDist !== 0) e.preventDefault();
			})
		});

		window.addEventListener('mousemove', e => this.onPointerMove(e.pageX));
		window.addEventListener('touchmove', e => {
			const diffY = Math.abs(this.y - e.touches[0].pageY);
			if (diffY > 20 && Math.abs(this.totalDist) < 20) this.slidingVertically = true;
			if (!this.slidingVertically) this.onPointerMove(e.touches[0].pageX);
			this.y = e.touches[0].pageY;
		});
		window.addEventListener('mouseup', () => this.onPointerUp());
		window.addEventListener('touchend', () => this.onPointerUp());
		window.addEventListener('keydown', e => this.onKeyDown(e.key));
		window.addEventListener('resize', () => this.onResize());

		this.observer = new IntersectionObserver(([entry]) => this.inView = entry.isIntersecting, { threshold: 0 })
		this.observer.observe(this.slider);
	}

	onResize() {
		this.calcSlideWidth();
		this.setSliderPos(this.currentSlide, false);
		const w = window.innerWidth;
		if (w >= 1024) {
			this.nbrPerSlide = 3;
			this.velocity = 10;
		} else if (w >= 768) {
			this.nbrPerSlide = 2;
			this.velocity = 12;
		} else {
			this.nbrPerSlide = 1;
			this.velocity = 20;
		}
	}

	calcSlideWidth() {
		this.slideWidth = this.slides[0].clientWidth;
	}

	onPointerDown(x) {
		clearInterval(this.interval);
		this.dragging = true;
		this.pointerPos = { x };
		this.lastPointerPos = { x };
		this.totalDist = 0;
		this.distLog = [];
		this.stopMomentum();
		this.updateSliderPosLoop();
	}

	onPointerMove(x) {
		if (this.dragging) this.pointerPos = { x };
	}

	onPointerUp() {
		if (!this.dragging) return;

		this.dragging = false;

		const releaseSpeed = this.distLog.reduce((a, b) => a + b, 0) / (this.distLog.length || 1);

		let targetX = this.sliderPos.x + releaseSpeed * this.velocity;
		targetX = Math.round(targetX / this.slideWidth) * this.slideWidth;
		let targetSlide = -targetX / this.slideWidth;
		let excess = 0;

		if (targetSlide < 0) {
			excess = targetSlide;
			targetSlide = 0;
		} else if (targetSlide >= this.slides.length - this.nbrPerSlide) {
			excess = targetSlide - (this.slides.length - this.nbrPerSlide);
			targetSlide = this.slides.length - this.nbrPerSlide;
		}
		if (excess !== 0) targetX = -targetSlide * this.slideWidth;

		this.momentumTween = gsap.to(this.sliderPos, {
			duration: this.duration - Math.abs(excess) / 20,
			x: targetX,
			ease: 'power2',
			onUpdate: () => this.updateSliderPos(),
			onComplete: () => this.updateSliderPos(),
		});
	}

	updateSliderPos() {
		gsap.set(this.slidesWrapper, { x: this.sliderPos.x, lazy: true });
		this.lastSliderPos.x = this.sliderPos.x;
		this.setIndex(Math.round(-this.sliderPos.x / this.slideWidth));
	};

	stopMomentum() {
		if (!this.momentumTween) return;
		this.momentumTween.kill();
		this.momentumTween = null;
	}

	setSliderPos(index, isAnimated = true) {
		this.stopMomentum();
		this.momentumTween = gsap.to(this.sliderPos, {
			duration: isAnimated ? this.duration : 0,
			x: -index * this.slideWidth,
			ease: 'power4',
			onUpdate: () => this.updateSliderPos(),
			onComplete: () => this.updateSliderPos(),
		});
	}

	updateSliderPosLoop() {
		if (!this.dragging || !this.inView) return;
		this.updateSliderPos();
		const dist = this.pointerPos.x - this.lastPointerPos.x;
		this.lastPointerPos.x = this.pointerPos.x;
		this.totalDist += dist;
		this.sliderPos.x += dist;
		this.distLog.push(dist);
		while (this.distLog.length > 10) this.distLog.splice(0, 1);
		requestAnimationFrame(() => this.updateSliderPosLoop());
	};

	onKeyDown(key) {
		clearInterval(this.interval);
		if (key === 'ArrowRight') this.next();
		else if (key === 'ArrowLeft') this.prev();
	};

	prev() {
		if (this.currentSlide > 0 && this.inView) {
			this.setSliderPos(this.currentSlide - 1);
		}
	};

	next() {
		if (this.currentSlide < this.slides.length - this.nbrPerSlide && this.inView) {
			this.setSliderPos(this.currentSlide + 1);
		}
	};

	setIndex(index) {
		if (index === this.currentSlide || index < 0 || index > this.slides.length - this.nbrPerSlide) return;
		this.currentSlide = index;

		if (index === 0) this.btnLeft.setAttribute('disabled', true);
		else this.btnLeft.removeAttribute('disabled');

		if (index === this.slides.length - this.nbrPerSlide) this.btnRight.setAttribute('disabled', true);
		else this.btnRight.removeAttribute('disabled');
	}
}

if (document.querySelectorAll('#sliderActualites .slidesWrapper>li').length) new SliderActualites('sliderActualites');
